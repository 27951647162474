.section4-container{
    /* height: 650px; */
    background-color: var(--primaryGreen);
    box-sizing: border-box;
    padding: 75px 2%;
    color: white;
}

.section4-container .section-heading{
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 50px;
    color: var(--textGray);
}

.card-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8%;
}

.card{
    width: 30%;
    height: 400px;
    background-color: #FCFCFA;
    color:  var(--offBlack);
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 28px;
    border-radius: 16px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  
}
.card-details {
    text-align: left;
    margin-top: 25px;
    padding: 0 9%;
    font-weight: 400;
    font-size: 16px;
}
.card-details .card-heading{
    font-size: 1.3rem;
    margin-bottom: 10px;
    font-weight: 600;
}
.card .card-banner img{
    width: 84%;
    
}

@media only screen and (max-width: 760px) {
    .section4-container{
        height: fit-content;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .card-container{
        flex-direction: column;
        row-gap: 35px;
    }
    .card{
        width: 100%;
        height: 220px;
    }
    .card .card-banner img{
        width: 82%;
        
    }
}

@media only screen and (min-width: 1450px) {
.card{
    height: 470px;
}
}