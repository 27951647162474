.outer-container{
    width: 100vw;
    height: 100vh;
    background-color: var(--backgroundGray)
}

.mobile-container{
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    text-align: center;
    color: rgba(51, 51, 51, 1);
}
.mobile-container img{
    width: 70%;

}
.mobile-container .sub-title{
    font-size: 16px;
    font-weight: 600;
}
.mobile-container .title{
    font-size: 24px;
    font-weight: 600;
    margin-top: 15px;
}
@media only screen and (max-width: 760px){
    .mobile-container img{
        width: 90%;
    }
    .mobile-container .sub-title{
        font-size: 12px;
    }
    .mobile-container .title{
        font-size: 18px;

    }
}
