.review-conainer{
    text-align: center;
    width: 100%;
    background-color: var(--backgroundGray);  
}
.review-conainer .banner{
    width: 250px;
    margin: auto;
    margin-bottom: 10px;
}
.review-conainer .banner > img{
    width: 100%;
}
.review-conainer > .heading{
    padding-top: 70px;
    margin-bottom: 35px;
    font-weight: 600;
    font-size: 32px;
    color: var(--offBlack);
}

.review-conainer > .sub-heading{
    width: 650px;
    margin: auto;
    font-weight: 400;
    font-size: 16px;
    color: var(--offBlack);
    padding-bottom: 20px;
}

.review-conainer > .review-form{
    width:50%;
    margin: auto;
    padding-top: 40px;
}

.review-form > .container{
    display: flex;
    column-gap: 25px;
    margin-bottom: 15px;
}
.review-form > .container > .heading{
    width:25%;
    /* height: 35px; */
    display: flex;
    justify-content: flex-start;
    padding-left: 1.8%;
    align-items: center;
    background-color: #A2D6AF;
    font-weight: 600;
    font-size: 12px;
}
.review-form > .container > .details{
    width:80%;
    /* height: 35px; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #DDE8E3;
    padding: 10px;
    font-weight: 400;

    text-align:start;
}

.review-conainer > .button-container{
    width:420px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 40px;
    padding-bottom: 20px;
    row-gap: 15px;
}

.review-conainer > .button-container > .back-btn,.review-conainer > .button-container > .submit-btn{
    width: 200px;
    height: 40px;
    border: 1px solid #d3d3d3;
    font-weight: 600;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
  
}
.review-conainer>.button-container>.submit-btn:hover{
    background-color: var(--secondaryOrange);
}
.review-conainer>.button-container>.submit-btn:active{
    background-color: var(--tertiaryOrange);
}
.back-btn{
    background-color: var(--backgroundGray);
    color: var(--offBlack);
}
.back-btn:hover{
    background-color: var(--primaryblack);
    color: #262626;
}
.submit-btn{
    background-color: var(--orange);
    color: white;  
}
.review-conainer > .regulation{
    padding-top: 70px;
    padding-bottom: 70px;
    font-weight: 400;
    font-size: 14px;
}

@media only screen and (max-width: 760px) {
    .review-conainer{
        width: 100%;
    }
    .review-conainer > .banner-img{
        width:95%
    }
    .review-conainer > .sub-heading , .review-conainer > .button-container{
        width: 95%;
    }
    .review-conainer > .review-form{
        width: 95%;
    }
    .review-form > .container > .details{
       padding-left: 1%;
       font-size: 0.8rem;
    }
    .review-form > .container > .heading{
        font-size: 0.6rem;
    }
    .review-conainer > .regulation{
        padding-top: 50px;
    }
}