

.ad-form-container {
  width: 27%;
  height: 100%;
  position: fixed;
  top: 0;
  right: -100%;
  /* left: 73%; */
  background-color: #fcfcfa;
  padding: 2% 2%;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  overflow: scroll;
  overflow-x: hidden;
  z-index: 1000;
  transition:right 500ms;
}

.ad-form-container.active{
  right: 0; /* Slide in when active */
  transition: right 500ms;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The sliders */
.sliders {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 0.8px solid #333333;
}

.sliders:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 3px;
  background-color: #999999;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .sliders {
  background-color: #dde8e3;
}

input:focus + .sliders {
  box-shadow: 0 0 1px #dde8e3;
}

input:checked + .sliders:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: #58b56f;
}

/* Rounded sliderss */
.sliders.round {
  border-radius: 34px;
}

.sliders.round:before {
  border-radius: 50%;
}

.ad-form-container > .cross-icon {
  text-align: right;
  margin-bottom: 25px;
}

.ad-form-container > .top-container {
  width: 90%;
  margin: auto;
}
.top-container > .switch-container {
  display: flex;
  margin-bottom: 25px;
  justify-content: space-between;
  align-items: center;
}

.switch-container > p {
  font-weight: 600;
  font-size: 14px;
  color: var(--offBlack);
}

.addtional-form-container {
  width: 98%;
  margin: auto;
}
.addtional-form-container > .input-container {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
}
.addtional-form-container > .input-container > label {
  font-weight: 600;
  font-size: 16px;
  color: var(--offBlack);
  margin-bottom: 25px;
}
.addtional-form-container > .input-container > input {
  height: 45px;
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  padding-left: 3%;
  margin-bottom: 15px;
}
.addtional-form-container > .input-container > .instruction{
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: black;
    margin-bottom: 30px;
}

.addtional-form-container > .button-container{
    display: flex;
    justify-content: space-around;
    row-gap: 16px;
    margin-bottom: 35px;
}

.addtional-form-container > .button-container >button{
    width:45%;
    height: 40px;
    border-radius: 23px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}
.addtional-form-container > .button-container > .reset-btn{
    background-color: #fcfcfa;
     border: 1px solid #d3d3d3
}
.addtional-form-container > .button-container > .apply-btn{
    background-color: var(--primaryGreen);
    border: none;
    color: white;
}
.addtional-form-container > .button-container > .apply-btn:hover{
  background-color: var(--secondaryGreen);
}
.divider{
    width: 100%;
    border-bottom: 1px solid #999999;
}

@media only screen and (max-width: 1200px) {
    .ad-form-container{
        width: 40%;
        margin: auto;
        left:60%;
    }
    .ad-form-container > .cross-icon{
        margin-top: 15px;
        margin-right: 15px;
    }
    .top-container{
        width: 90%;
    }
    .addtional-form-container{
        width:95%;
    }
}