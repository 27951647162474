.passwaord-container {
    width: 450px;
    height: fit-content;
    position: fixed;
    margin:  auto;
    inset: 0;
    background-color: #FCFCFA;
    z-index: 10;
    padding: 1%;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
}

.passwaord-container>.close-container {
    text-align: right;
    margin: 5px 5px 0 0;
}

.passwaord-container>.close-container>img {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.passwaord-container>.text-container {
    text-align: center;
    width: 85%;
    margin: auto;
    margin-bottom: 40px;
}

.passwaord-container .text-container>.heading {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: var(--offBlack);
    padding-bottom: 35px;
    padding-top: 20px;
}

.text-container>.sub-heading {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
}

.passwaord-container>.input-password-container {
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 8px;
    position: relative;
}

.input-password-container>label {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2C2C2C;
}

.input_box {
    position: relative;
}

.input_box>input {
    width: 100%;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    border-radius: 8px;
    /* margin-bottom: 30px; */
    padding-left: 15px;
    font-size: 16px;
    /* font-weight: 600; */

}

.eye-icon {
    position: absolute;
    top: 25%;
    right: 5%;
    cursor: pointer;

}

.passwaord-container>.create-btn {
    width: 85%;
    margin: auto;

}

.create-btn>button {
    width: 100%;
    height: 40px;
    margin-top: 30px;

    background-color: var(--orange);
    color: white;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    /* padding: 2%; */
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
}
.create-btn>button:hover {
background-color: var(--secondaryOrange);
}
.create-btn>button:active {
    background-color: var(--tertiaryOrange);
}
.passwaord-container>.regulations {
    width: 80%;
    margin: auto;
    font-size: 10px;
    font-weight: 400;
    padding-bottom: 35px;
}



.errorMsg p {
    /* background-color: red; */
    color: red;
    /* font-weight: 600; */
    width: 80%;
    /* height: 40px; */

    margin: auto;
    margin-top: 2px;
    font-size: 14px;
}

.errorMsg >p::before {
    content: '*';
    color: red;

}
.error-msg-box{
    width: 80%;
    margin: auto;
}

@media only screen and (max-width: 600px) {
    .passwaord-container {
        width: 95%;
        /* margin-top: 50%;
        margin-left: 2%; */
    }

    .passwaord-container>.close-container {
        margin-top: 15px;
        margin-right: 15px;
    }
}