.alert {
   
    position: fixed;
    inset: 0;
    background-color: rgba(0,0,0,0.4);
    backdrop-filter: blur(6px);
    z-index: 60;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.alert-content {
    font-weight: 600;
    text-align: center;
    color: var(--offBlack);
}

.alert-box {
    background-color: #FCFCFA;
    max-width: 330px;
    min-width: 400px;
    border-radius: 16px;
    overflow: hidden;
    padding: 35px;
    /* box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1); */
}
.alert-box .email{
    padding: 15px;
    text-align: center;
}
.alert-btn-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.alert-ok, .alert-cancel {
    width: 100%;
    height: 40px;
    border: none;
    cursor: pointer;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    border-radius: 20px;
}

.alert-ok {
    background-color: var(--orange);
}
.alert-ok:hover {
    background-color: var(--secondaryOrange);
    
}

.alert-cancel {
    background-color: var(--backgroundGray);
    color:var( --offBlack) ;
}
