.success-container{
    text-align: center;
    width: 100%;
    height: 90vh;
    background-color: var(--backgroundGray);  
    color: var(--offBlack);
    padding: 5% 0;
}

.success-container > .heading{
    padding-bottom: 2%;
    font-weight: 600;
    font-size: 32px;
    
}

.success-container > .sub-heading{
    width: 500px;
    margin: auto;
    font-weight: 400;
    font-size: 24px;
    padding-bottom: 2%;
}

.success-container > .sub-text{
    width: 400px;
    margin: auto;
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 2%;
}

.success-container > .home-btn > button{
    width: 250px;
    height: 40px;
    border:none;
    font-weight: 600;
    border-radius: 25px;
    cursor: pointer;
    background-color: var(--orange);
    color: white;
    font-size: 16px;
}
.success-container > .regulation{
    padding: 4% 0 5% 0;
    font-weight: 400;
    font-size: 14px;
}


    @media (min-height: 700px) {
        .success-container{
            padding: 9% 0;
        }
    
        .success-container > .regulation{
            padding-top: 7%;
        }
    
      }
    