.login-container {
    width: 450px;
    height: fit-content;
    position: fixed;
    margin: auto;
    inset: 0;
    background-color: #FCFCFA;
    z-index: 10;
    padding:20px 20px 24px 20px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
    color: var(--offBlack);
}

.login-container>.close-button { 
    position: sticky;
    width: 100%;
    text-align: right;
    margin-bottom: 15px;
}

.login-container>.close-button>img {
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.login-container .text-container {
    margin-bottom: 10px;
}

.login-container .text-container>.heading {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: var(--offBlack);
    margin-bottom: 20px;
    text-align: center;
}

.login-container .text-container>.sub-text {
    width: 380px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin: auto;
}

.login-container>.button-container {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.button-container>.login-btn {
    width: 380px;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    padding-left: 3%;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 40px;
    column-gap: 60px;
    font-weight: 800;
    font-size: 14px;
    color: #2C2C2C;
    cursor: pointer;
}

.login-btn .google {
    margin-left: 20px;
}

.login-btn .apple {
    margin-left: 28px;
}
.login-btn .facebook{
    margin-left: 15px;
}
.login-container .division-container {
    width: 280px;
    /* margin: auto; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 15px;
}

.login-container .division-container .line {
    width: 100px;
    border-bottom: 2px solid rgb(155, 154, 154,5);
}

.login-container>.bottom-container {
    width: 380px;
    /* margin: auto; */
}

.bottom-container .input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.bottom-container .input-container>label {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

.bottom-container .input-container>input {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #999999;
    border-radius: 8px;
    height: 40px;
    padding-left: 15px;
    font-size: 16px;
}

.bottom-container .pass-input-container {
    position: relative;
}

.pass-input-container>.eye-icon,.input-container-box >.eye-icon{
    position: absolute;
    top: 35%;
    right: 3%;
    cursor: pointer;
}

.bottom-container>.login-button>button {
    width: 100%;
    height: 40px;
    background-color: var(--orange);
    color: white;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
}
.bottom-container>.login-button>button:hover {
    background-color: var(--secondaryOrange);
}
.bottom-container>.login-button>button:active {
    background-color: var(--tertiaryOrange);
}

.bottom-container>.login-button {
    margin-bottom: 10px;
}

.errorMsg::before {
    content: '*';
    color: red;
}

.errorMsg {
    color: red;
    font-size: 14px;
    /* margin-bottom: 5px; */
}

@media only screen and (max-width: 500px) {
    .login-container {
        width: 95%;
        margin-top: 12%;
        padding: 5% 1%;
        height: fit-content;
    }

    .login-container .close-button {
        margin-right: 25px;
        margin-top: 10px;
    }

    .text-container,
    .button-container,
    .bottom-container,
    .division-container,
    .input-container {
        width: 80%;
        margin: auto;
    }

    .login-container .text-container>.sub-text {
        width: 100%;
        text-align: center;
    }

    .login-container .text-container>.heading {
        font-size: 16px;
    }

    .login-container .login-btn {
        width: 100%;
        column-gap: 8px;
        font-size: smaller;

    }

    .login-btn .google,
    .login-btn .apple {
        margin-left: 15%;
    }

    .login-btn .facebook {
        margin-left: 12%;
    }

    .login-container .division-container .line {
        width: 100px;
        border-bottom: 1px solid black;
    }

    .login-container .login-button {
        width: 90%;
        margin: auto;
        padding-bottom: 25px;
    }
}