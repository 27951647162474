.change-pass-container{
    width: 400px;
    height: fit-content;
    position: fixed;
    margin: auto;
    inset: 0;
    background-color:#FCFCFA;
    z-index: 10;
    padding: 24px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    color: var(--offBlack);
}

.change-pass-container .close-button{
    text-align: right;
}

.change-pass-container .heading-text{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: var(--offBlack);
    margin: 25px 0;
}

.change-pass-input-container {
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 8px;
    position: relative;
}

.change-pass-input-container > label{
    font-size: 14px;
    font-weight: 600;
    color: var(--offBlack);
}

.change-pass-input-container > input{
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #999999;
    border-radius: 8px;
    font-size: 16px;
    padding-left: 15px;
    color: var(--offBlack);
    margin-bottom: 15px;
}

.change-pass-input-container .eye-icon{
    position: absolute;
    top: 45%;
}
.change-pass-container .change-pass-btn{
    width: 85%;
    margin: auto;
}

.change-pass-container .change-pass-btn > button{
    width: 100%;
    height: 40px;
    background-color: var(--orange);
    color: white;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    margin-top: 25px;
    margin-bottom: 10px;
}


@media only screen and (max-width: 600px) {
    .change-pass-container {
        width: 95%;
        margin-top:45%;
        margin-left: 2%;
    }
    .change-pass-container .close-button{
        margin-right: 15px;
        margin-top: 15px;
    }
}