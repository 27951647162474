.verify-container {
    width: 400px;
    height: fit-content;
    position: fixed;
    margin: auto;
    inset: 0;
    background-color: #FCFCFA;
    z-index: 10;
    padding: 1% ;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.verify-container-inner{
    margin: auto;
  
}

.verify-container-inner>.close-container {
    text-align: right;
    margin: 5px 5px 0 0;
}

.verify-container-inner>.close-container>img {
    cursor: pointer;
    width: 20px;
    height: 20px;
    /* margin-bottom: 30px; */
}

.verify-container-inner>.text-container {
    text-align: center;
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
    padding-top: 20px;
}

.text-container>.headline {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color:var(--offBlack);
    margin-bottom: 25px;
}

.text-container>.sub-heading {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 10px;
}

.verify-container-inner>.code-input-container {
    width: 90%;
    margin: auto;
    text-align: center;
}

.verify-container-inner>.code-input-container>.heading {
    font-weight: 600;
    font-size: 14px;
    color: #2C2C2C;
    margin-bottom: 15px;
}

.code-input-container>input {
    height: 45px;
    width: 30px;
    font-size: 18px;
    font-weight: 600;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    scroll-behavior: unset;
    
}

.verify-container-inner>.button-container {
    width: 80%;
    margin: auto;
}

.verify-container-inner>.button-container>button {
    width: 100%;
    height: 40px;
    background-color: var(--orange);
    color: white;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    /* padding: 2%; */
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 35px;
}
.verify-container-inner>.button-container>button:hover{
    background-color: var(--secondaryOrange);
}
.verify-container-inner>.button-container>button:active{
    background-color: var(--tertiaryOrange);
}

.verify-container-inner>.regulation {
    width: 80%;
    margin: auto;
    font-size: 10px;
    font-weight: 400;
    padding-bottom: 35px;
    line-height: 15px;
}

.verify-container-inner>.resend-code {
    width: 80%;
    margin: auto;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 25px;
}

@media only screen and (max-width: 600px) {
    .verify-container {
        width: 95%;
        margin-top: 45%;
        margin-left: 2%;
    }

    .verify-container-inner>.close-container {
        margin-top: 15px;
        margin-right: 15px;
    }
}