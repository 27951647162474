@font-face {
  font-family: "Plus Jakarta Sans";
  src:local("Plus Jakarta Sans"), url(../fonts/static/PlusJakartaSans-Regular.ttf) format('opentype');
}
body,button,input{
  font-family: "Plus Jakarta Sans" !important;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root{
  --primaryGreen: #30624C;
  --backgroundGray:  #F9FAF5;
  --primaryblack:rgba(0, 0, 0, 0.04);
  --secondaryblack:rgba(0, 0, 0, 0.02);
  --offBlack:  #333333;
  --orange:#EF4F2B;
  --secondaryOrange:#D9300B;
  --tertiaryOrange: #BF2A0A;
  --textGray: #E1E1E1;
  --secondaryGreen: #154731;
  --tertiaryGreen:#0F3022;
}
button:disabled{
  cursor: not-allowed !important;
}
/* ::-webkit-scrollbar
{
	width: 8px;
    height: 8px;
	background-color: #a19f9f; 
 }   */

input:focus{
  outline: none;
}
body{
  background-color: #DDE8E3;
}
.app{
  max-width: 1920px;
  max-height: 100%;
  overflow: auto;
  margin: auto;
  background-color: var(--backgroundGray);
}

.scroll{
  overflow:auto;
}


  /* Customize the scrollbar */
  /* .scroll::-webkit-scrollbar {
    width: 6px;
  } */

  /* Track */
  /* .scroll::-webkit-scrollbar-track {
    background: black;
  } */

  /* Handle */
  /* .scroll::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 25px;
  }

  /* Handle on hover */
  /* .scroll::-webkit-scrollbar-thumb:hover {
    background: #c3c2c2;
  }  */

  ::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
    border-radius: 1px;
    background-color: #ffffff;
  }
  
  ::-webkit-scrollbar
  {
    width: 8px;
      height: 8px;
    background-color: #ffffff;
  }
  
  ::-webkit-scrollbar-thumb
  {
    border-radius: 6px;
    height: 50px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
    background-color: #cacaca;
  }