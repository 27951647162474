/* CustomDropdown.css */

.custom-dropdown {
    position: relative;
    width: 220px;
}

.dropdown-header {
    padding: 7px 10px;
    border: 1px solid #ccc;
    /* background-color: #fff; */
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    gap: 2px;

    align-items: center;
    border-radius: 8px;
    border: 1px;
    /* background: linear-gradient(0deg, #FCFCFA, #FCFCFA),
        linear-gradient(0deg, #E1E1E1, #E1E1E1); */

    border: 1px solid #adadad;
    box-shadow: 1px 1px 4px 0px #00000026;
    font-weight: 400;
    color: #333333;
}

.dropdown-selected{
    background: #30624C ;
    color: white;
}

.count-selected{
    padding:2px 7px ;
    background-color: white;
    color: black;
    border-radius: 50%;
    margin-inline: 5px;
}


.down-arrow-icon {
    padding-top: 5px;
}

.dropdown-options {
    list-style: none;
    padding: 0;
    margin-top: 10px;
    position: absolute;

    width: 100%;
    width: Hug (252px);
    height: Hug (402px);
    border-radius: 8px;
    border: 1px;
    background: linear-gradient(0deg, #FCFCFA, #FCFCFA),
        linear-gradient(0deg, #E1E1E1, #E1E1E1);

    border: 1px solid #E1E1E1;
    box-shadow: 2px 2px 4px 0px #00000026;

    display: none;
    z-index: 99;
}

.dropdown-options .cancel-icon {
    text-align: end;
    padding: 10px 10px 0;
    color: #999999;
    cursor: pointer;
}

.down-arrow-icon {
    font-size: 18px;
}

.dw-func-box {
    padding: 10px;
    display: flex;
    justify-content: space-between;

}

.dw-func-box button {
    width: 45%;
    padding: 5px 10px;
    border-radius: 50px;
    border: 1px solid #999999;
    font-weight: 500;
    cursor: pointer;
}

.btn-white {
    background: white;
    color: black;
}

.btn-green {
    background: #30624C;
    color: white;
}

/* Show options when dropdown is open */
.custom-dropdown.open .dropdown-options {
    display: block;
    min-width: 200px;
}


.option-wrapper {
    margin-bottom: 10px;
    padding: 0 10px;
}

.dropdown-option {
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    gap: 10px;
}

.dropdown-option:hover {
    background: #DDE8E3;
    
}

.dropdown-option label {
    font-size: 13px;

}

.dropdown-option input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #30624C;
    border-radius: 50%;
    margin-right: 8px;
    position: relative; /* Add this line */
  }
  
  .dropdown-option input[type="radio"]::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    transition: background-color 0.3s ease;
  }
  
  .dropdown-option input[type="radio"]:checked::before {
    background-color: #30624C ;
  }
  
  

  .dropdown-option input[type="checkbox"] {
    width: 20px; /* Increase size */
    height: 20px; /* Increase size */
    background-color: #e0e0e0; /* Change background color */
  }
 
  .dropdown-option input[type="checkbox"]:checked {
    accent-color:#30624C     ; /* Change background color when checked */
  }
 
  
  
  
  



/* Rotate arrow when dropdown is open */
.custom-dropdown.open .dropdown-arrow {
    border-bottom-color: #000;
    border-top-color: transparent;

    /* transform: rotate(180deg); */
}

/* Add styles for the selected option */
.custom-dropdown .selected-option {
    font-weight: bold;
}


@media screen and (max-width:724px) {
    .custom-dropdown {
        /* position: relative; */
        width: 60%;
    }
}

@media screen and (max-width:524px) {
    .custom-dropdown {

        width: 75%;
    }
}

@media screen and (max-width:424px) {
    .custom-dropdown {

        width: 90%;
    }
}