.cookie-container{
    background-color: var(--backgroundGray);
    padding: 5% 10%;
}
.cookie-container .title{
    text-align: center;
    padding-bottom: 50px;
}
.cookie-container .subtitle{
    padding: 25px 0px 20px 0;
    font-size: 18px;
    font-weight: 600;
}
.cookie-container .para{
    padding-bottom: 20px;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
}
.cookie-container .list{
    padding: 20px;
}
.cookie-container .list > li{
    margin-bottom: 10px;
}