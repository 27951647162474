.payment-container{
  background-color: var(--backgroundGray);
  color: var(--offBlack);
}
.main-payment-container{
  display: flex;
  padding: 25px 8%;
  justify-content: space-around;
}
.payment-left{
  width: 40%;
padding-top: 7%;
}
.payment-banner-img{
  width: 380px;
  margin: auto;
  margin-bottom: 25px;
}
.payment-banner-img > img{
  width: 100%;
}
.payment-left .plan-details{
  width: 380px;
  margin: auto;
}

.payment-left .plan-details .heading{
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 15px;
}
.payment-left .plan-details .amount-card{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
}
.payment-left .plan-details .amount-card .price{
  font-size: 40px;
  font-weight: 700;
}
.payment-left .plan-details .billing{
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  margin-top: 25px;
}

.payment-left .plan-details .billing .billing-details-row{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 18px;
}

.payment-left .plan-details .billing .bill-total{
  padding-top: 25px;
  font-weight: 600;
}

.capture-card-details {
    width: 40%;
    margin: auto;
    padding-top: 20px;
    /* padding-bottom: 35px; */
  }
  .payment-heading-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  .capture-card-details__section__heading {
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 24px;
    color: var(--offBlack);
  }
  
  .capture-card-details input {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #c9c8c8;
    border-radius: 8px;
    height: 40px;
    padding-left: 15px;
    margin-bottom: 15px;
  }
  .capture-card-details input[type=number] {
    height: 40px;
    padding-left: 15px;
    margin-bottom: 24px;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: 1px solid #c9c8c8;
  }
   
  .capture-card-details section {
    display: flex;
    flex-direction: column;
  }
  
  #billing-address {
    margin-top: 20px;
  }
 .input-row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
  }

  /* #billing-address .input-row .MuiFormControl-root{
    width: 50% !important;
  } */
  .card-secure-info {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
  }
  
  .card-secure-info__input {
    flex: 1;
    width: 100%;
  }
  
  .card-secure-info .country-selector {
    flex: 1;
  }
  
  .card-secure-info .country-selector__value-container {
    position: initial;
  }
  
  .card-icons-container {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
    align-items: center;
  }
  
  .card-icons > img {
    width: 40px;
    height: 40px;
  }

  .card-details-btn{
    text-align: center;
  }
  .card-details-btn > button{
    width: 100%;
    height: 40px;
    background-color: var(--orange);
    color: white;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    margin-bottom: 35px;
    margin-top: 35px;
  }
  
  .capture-card-details input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  @media only screen and (max-width: 1400px) {
    .main-payment-container{
  padding-top: 5% ;
  justify-content: space-between;
}
.payment-left{
  width: 35%;
  padding-top: 10%;
}
.payment-banner-img{
  width: 300px;
}
.capture-card-details{
  width: 55%;
}
.payment-left .plan-details{
  width: 300px;
}
  }

.label-input-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 10px;
}
.label-input-container  label{
  font-weight:600;
  font-size: 14px;
  color: var(--offBlack);
}