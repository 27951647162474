.section5-top-container {
    display: flex;
    justify-content: space-around;
    column-gap: 25px;
    align-items: center;
    /* height: 630px; */
    box-sizing: border-box;
    padding: 5% 13%;
    background-color: var(--backgroundGray);
}

.plan-card {
    height: 450px;
    width: 430px;
    border: 0.5px solid rgb(189, 188, 188);
    padding: 3% 3%;
    border-radius: 15px;
}

.plan-card .card-heading {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 15px;
    font-weight: 600;
    font-size: 24px;
    color: var(--offBlack);
}

.plan-card .plan-heading {
    padding-left: 4%;
    margin-bottom: 30px;
}

.plan-card .plan-heading .text span {
    font-weight: 600;
    font-size: 20px;
    color: var(--offBlack);
}

.plan-card .plan-heading .text {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 15px;
    margin-bottom: 10px;
    color: var(--offBlack);
}
.plan-card .plan-heading .text .subtext{
    padding-top: 5px;
}

.plan-card .subscribe-btn {
    text-align: center;
    margin-top: 55px;
    margin-bottom: 20px;
}

.plan-card .subscribe-btn>button {
    min-width: 100%;
    height: 45px;
    border: none;
    background-color: var(--orange);
    font-weight: 600;
    font-size: 16px;
    color: white;
    border-radius: 50px;
    cursor: pointer;
    padding-inline: 30px;
}

.plan-card .subscribe-btn>button:disabled {
    background-color: #ee8c76;
}
.plan-card .subscribe-btn>button:hover{
    background-color: var(--secondaryOrange);
}
.plan-card .subscribe-btn>button:active{
    background-color: var(--tertiaryOrange);
}
.section5-bottom-container {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondaryGreen);
}

.section5-buttton>button {
    width: 190px;
    height: 50px;
    /* padding: 15px; */
    border: none;
    background-color: var(--orange);
    font-weight: 600;
    font-size: 16px;
    color: white;
    border-radius: 50px;
    cursor: pointer;

}
@media only screen and (min-width: 1400px) {
    .section5-top-container {
        padding: 5% 15%;
    }
}
@media only screen and (max-width: 760px) {
    .section5-top-container {
        flex-direction: column;
        height: fit-content;
        padding: 5%;
    }

    .plan-card {
        width: 100%;
        margin-bottom: 40px;
        margin-top: 30px;
    }

    .section5-bottom-container {
        height: 150px;
    }
}