.slider-container{
    width: 100%;
    background-color: #F9FAF5;
    padding: 55px 90px 60px 90px;
    position:relative ;
}

.blog-container{
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 35px;
    gap: 30px;
}

.blog{
    /* width:400px;
    height: 105px;
    background-color:  #E1E1E1;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; */
}

.slick-slide>div .blog .text{
    text-align: left;
    font-weight: 400;
   font-size: 16px;
   color:  #333333;;
}

.slick-slide>div .blog .name{
    text-align: right;
    font-weight: bold;
    color: var(--offBlack);
    margin-top: 25px;
}
/* the below one is related to pointer below the curousel */
.bJNoTF{
    display: none !important;
}
.slider-container button{
width: 65px;
height: 65px;
border-radius: 22px;
 color: white;   
 z-index: 500; 
}


@media only screen and (max-width: 760px){

 .blog{
    width: 80%;
    height: fit-content;
    padding: 6%;
 }
}

.slick-slide>div{
    width:75%;
    margin: auto;
    height: 105px;
    background-color:  #E1E1E1;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 70px;
}
.slick-prev, .slick-next{
    top: 48% !important;
}
.slick-prev{
    right: -35px;
}
.slick-next::before{
    content: url('../../../images/right.svg') !important;
}
.slick-prev::before{
    content: url('../../../images/left.svg') !important;

}

@media only screen and (max-width: 1150px){
    .slick-slide>div{
        width: 450px;
    }
}