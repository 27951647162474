.filter-container{
    text-align: center;
    width: 100%;
    background-color: var(--backgroundGray); 
    height: 90%; 
    padding: 4.5% 0;
}

.filter-container > .heading{
    padding-bottom: 2%;
    font-weight: 600;
    font-size: 32px;
    color: var(--offBlack);
}

.filter-container > .sub-heading{
    width: 650px;
    margin: auto;
    font-weight: 400;
    font-size: 16px;
    color: var(--offBlack);
    padding-bottom: 2%;
}

.filter-container > .drop-down-container{
    text-align: center;
    display: flex;
    flex-wrap:wrap;
    row-gap: 15px;
    justify-content: center;
    align-items: flex-start;
    column-gap: 15px;
    margin: auto;
}

.drop-down-container .more-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    padding: 10px;
    width: 220px;
    border: 1px solid #adadad;
    font-size: 1rem;
    color: #333333;
    cursor: pointer;
}

.filter-container > .button-container{
    width:500px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 2%;
    row-gap: 10px;
    /* padding-bottom: 1.5%; */
}

.filter-container > .button-container > .back-btn,.filter-container > .button-container > .next-btn{
    width: 230px;
    height: 40px;
    border: 1px solid #d3d3d3;
    font-weight: 600;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
  
}
.filter-container>.button-container>.next-btn:hover{
    background-color: var(--secondaryOrange);
}
.filter-container>.button-container>.next-btn:active{
    background-color: var(--tertiaryOrange);
}
.back-btn{
    color: var(--offBlack);
    background-color: var(--backgroundGray);
}
.next-btn{
    background-color: var(--orange);
    color: white;
   
}
.filter-container > .regulation{
    /* padding-top: 80px;
    padding-bottom: 80px; */
    padding: 6% 0 5% 0;
    font-weight: 400;
    font-size: 14px;
}
.filter-container .dot-container{
    padding-top: 1.5%;
}
@media only screen and (max-width: 1440px) {
    
    .filter-container .drop-down-container{
        width: 950px;
        margin: auto;
        padding: 0;
        justify-content: flex-start;
    }
    .filter-container > .button-container{
        padding-top: 5%;
    }
    
    }
@media only screen and (max-width: 1150px) {

.filter-container .drop-down-container{
    width: 550px;
    padding: 0;
    column-gap: 10px;
    justify-content: center;
}
.filter-container .button-container{
    width: 500px;
}
.filter-container .dot-container{
    padding-top: 2%;
}
}
@media only screen and (min-width: 1500px) {
    /* .filter-container > .regulation{
        padding-top: 50px;
        padding-bottom: 70px;
    } */
}
@media (min-height: 750px) {
    .filter-container{
        padding: 8%;
    }

    .filter-container > .regulation{
        padding-top: 7%;
    }

  }