.section2-container{
    /* height: 500px; */
    width: 100%;
    display: flex;
    padding: 5% 7% 5% 5%;
    background-color: var(--primaryGreen);
    justify-content: space-between;
    align-items: center;
    color: var(--textGray);
}
.section2-left{
    width: 50%;
}
.section2-left > img{
    width: 90%;
}
.section2-right{
    width: 45%;
}
.section2-right .heading{
    text-align: left;
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 25px;
}

.section2-right .list-container .list{
    width: 80%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 25px;
    margin-bottom: 25px;
}


.list .list-text{
    /* height: 35px; */
    font-weight: 400;
    font-size: 16px;
    color: var(--textGray);
}
@media only screen and (max-width: 1200px){
    
}
@media only screen and (max-width: 760px){
    .section2-container{
        flex-direction: column-reverse;
        padding: 15% 2% 15% 2%;
        height: fit-content;
    }
    .section2-left,.section2-right{
        width: 100%;
        margin: auto;
        text-align: justify;
    }
    .section2-left > img{
        width: 90%;
    }
    .section2-right .heading{
        font-size: 1.5rem;
    }

    .list .list-text{
        text-align: left;
    }
}

