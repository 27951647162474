
.taginput-input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 55px;
    max-height: fit-content;
    background: #ffffff;
    border: 1px solid #999999;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    padding-left: 2%;
    margin-bottom: 15px;
    overflow: auto;
}
.taginput-input input{
    background-color: #ffffff;
    height: 30px;
    overflow: hidden;
}

.taginput-input:focus-within {
    outline: 1px solid #22a8f6;
}

.taginput-input-elem {
    flex: 1;
    border: none;
}

.taginput-input>input:focus {
    outline: transparent;
}

.taginput-tag {
    width: auto;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    list-style: none;
    border-radius: 2px;
    background:#DDE8E3 ;
    color: rgb(56, 56, 56);
    font-weight: 400;
    margin-right: 2px;
    margin-bottom: 3px;
    column-gap: 5px;
}

.taginput-tag-title {
    margin-top: -4px;
    color: rgb(56, 56, 56);
}

.taginput-tag-close-icon {
    /* display: block; */
    width: 20px;
    height: 20px;
   display: flex;
   justify-content: center;
   /* align-items: center; */
    margin-left: 8px;
    color: rgb(56, 56, 56);
    border-radius: 50%;
    /* background: var(--orange); */
    cursor: pointer;
    font-size: 1rem;
}
.tag-input-container > .instruction{
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: black;
    margin-bottom: 30px;
}

.tag-input-container .button-container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
}

.tag-input-container > .button-container >button{
    width:45%;
    height: 35px;
    border-radius: 23px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.tag-input-container > .button-container > .reset-btn{
    background-color: var(--backgroundGray);
     border: 1px solid #d3d3d3
}
.tag-input-container > .button-container > .reset-btn:hover{
    background-color: var(--primaryblack) ;
}
.tag-input-container > .button-container > .apply-btn{
    background-color: var(--primaryGreen);
    border: none;
    color: white;
}
.tag-input-container > .button-container > .apply-btn:hover{
    background-color: var(--secondaryGreen);
}
.tag-input-container > .button-container > .apply-btn:active{
    background-color: var(--tertiaryGreen);
}
.tag-input-container > .button-container > .apply-btn:disabled{
    background-color: #65a086;
}