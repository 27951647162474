.up_plan_alert {
 
    position: fixed;
    top: 25%;
    inset: 0;
    background-color: rgba(0,0,0,0.4);
    backdrop-filter: blur(6px);
    z-index: 60;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--offBlack);
}

.up_plan_alert-box {
    background-color: #FCFCFA;
    width: 400px;
    height: fit-content;
    border-radius: 16px;
    overflow: hidden;
    padding: 25px;
}
.alert-msg {
    padding:25px 30px 15px 30px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 26px;
}
.plan-details-box{
    width:90%;
    margin: auto;
    height: fit-content;
    border: 1px solid #999999;
    border-radius: 10px;
}

.plan-details-box > .subheading,.plan-details-box > .heading{
    text-align: center;
    padding-top: 10px;
    font-weight: 600;
}
.plan-details-box > .subheading{
    font-size: 20px;
}
.plan-details-box > .heading{
    font-size: 24px;
    padding-bottom: 20px;
}
.plan-details-box .plan-detail{
    width: 65%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    margin: auto;
    margin-bottom: 25px;
}
.plan-details-box .plan-detail .text{
    font-size: 16px;
    font-weight: 400;
}

.up_plan_alert-box .btn-container{
    display: flex;
    width: 90%;
    margin: auto;
    flex-direction: column;
    row-gap: 15px;
    margin-top: 30px;
    margin-bottom: 15px;
}
.up_plan_alert-box .btn-container  button{
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 20px;
    color: white;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}
.up_plan_alert-box .btn-container .upgrade{
    background-color: var(--orange);
    
}
.up_plan_alert-box .btn-container .upgrade:hover{
    background-color: var(--secondaryOrange);
}
.up_plan_alert-box .btn-container .cancel{
    border: 0.5px solid #b6b6b6;
    color: var(--offblack);
    background-color: #FCFCFA;
}
.up_plan_alert-box .btn-container .cancel:hover{
    background-color: #e9e8e8;
}