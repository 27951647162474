.upper-container{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 10;
    opacity: 0.6;
    background-color: black;
    position: fixed;
    top: 0;
    right: 0;
}
.header-container{
    height: 10%;
    display: flex;
    padding: 2% 8%;
    justify-content: space-between;
    align-items: flex-end;
    background-color:  var(--backgroundGray);
    border-bottom: 2px solid #cfcfce;
    margin: auto;
}

.header-left > img{
   width: 200px;
   cursor: pointer;
}
 .header-right {
    display: flex;
    justify-content: center;
    align-items: baseline;
    column-gap: 15px;
 }
 .header-right .begin-btn{
    height: 40px;
    border-radius: 40px;
    width: 150px;
    font-size: 16px;
    border: 1px solid #cfcfce;
    background-color: var(--backgroundGray);
    color: var(--offBlack);
    cursor: pointer;
    font-weight: 600;
    margin-left: 25px;
 }
 .header-right .begin-btn:hover{
   background-color: var(--primaryblack) ;
 }
 .header-right .header-btn{
    height: 40px;
    border-radius: 40px;
    width: 120px;
    font-size: 16px;
    border: 1px solid #cfcfce;
    cursor: pointer;
    font-weight: 600;
    margin-left: 25px;
 }

 .header-container .profile-container{
    width: 70px;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    column-gap: 7px;
    align-items: center;
    position: relative;
    border: 1px solid #cfcfce;
    border-radius: 25px;
    color: var(--offBlack);
    cursor: pointer;
    font-weight: 600;
    background-color: var(--backgroundGray);
    top: 6px;
    padding-left: 5px;
 }
 .profile-container .avtar{
    font-size: 35px;
 }
.profile-container .drop-down-container{
   width: 250px;
    position: absolute;
    background-color: white;
    top: 120%;
    right: -100px;
    cursor: pointer;
    font-weight: 600;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgb(229 231 235);
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}
.profile-container .list{
    padding: 10px 14px;
}
.profile-container .logout-btn {
   transition: 0.2s;
}
.profile-container .logout-btn:hover{
    background-color: var(--primaryGreen);
    color: white;
}
.header-right .sign-up-btn{
   background-color: var(--primaryGreen);
   color: white;
   border: none;
}
.header-right .sign-up-btn:hover{
   background-color: var(--secondaryGreen);
}
.header-right .sign-up-btn:active{
   background-color: var(--tertiaryGreen);
}
.header-right .sign-in-btn{
background-color: var(--backgroundGray);
color: var(--offBlack);
}
.header-right .sign-in-btn:hover{
   background-color: var(--primaryblack) ;
}

 /* @media only screen and (max-width: 760px) {
    .header-container{
        height: 75px;
    }

    .header-left > img{
      width: 80%;
      height: 95%;
    }
   .header-right{
    column-gap: 5px;
   }
   .header-right .header-btn{
    height: 35px;
    border-radius: 20px;
    width: 100px;
    font-size: 1rem;
    margin-left: 10px;
 }
 .header-right .begin-btn{
    width: 100px;
    font-size: small;
    height: 30px;
 }
 .header-container .profile-container{
    width: 70px;
    height: 30px;
 }
 .profile-container .avtar{
    font-size: 25px;
 }
 .profile-container .list{
    width: 70px;
 }
} */