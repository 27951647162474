.section1-container{
    display: flex;
    max-height: 1000px;
    padding: 5%;
    background-color: var(--backgroundGray);
}

.section1-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 60%;
    padding: 7%;
}

.section1-left > .heading{
    font-weight: 600;
   font-size: 48px;
   margin-bottom: 40px;
}

.section1-left > .subtext{
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 75px;
    color: var(--offBlack);
}

.section1-right {
    width: 40%;
}

.section1-right  .img-container{
    padding-left: 5%;
    height: 100%;
    width: 100%;
}
.secttion1-button{
display: flex;
}
.section1-button > button{
    padding: 5px;
    height: 48px;
    border: none;
    color: white;
    background-color:  var(--orange);
    border-radius: 48px;
    width: 190px;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    /* padding: 8%; */
}
.section1-button > button:hover{
    background-color: var(--secondaryOrange);
}
.section1-button > button:active{
    background-color: var(--tertiaryOrange);
}

@media only screen and (max-width: 760px) {
    .section1-container{
        flex-direction: column;
        height: fit-content;
        padding-bottom: 10%;
    }
    .section1-left,.section1-right{
        width: 100%;
    }
    .section1-left > .heading{
        font-size: 2rem;
    }
    .section1-left > .subtext{
        font-size: 1rem;
        margin-bottom: 35px;
    }
    .section1-right  .img-container{
        height: 75%;
        width: 95%;
        margin-top: 25px;
    }
}