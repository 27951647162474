.loading-modal {
    position: fixed;
    inset: 0;
    background-color: rgba(0,0,0,0.08);
    backdrop-filter: blur(6px);
    z-index: 30;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}