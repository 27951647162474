.main-container {
    text-align: center;
    width: 100%;
    height: 90vh;
    background-color: var(--backgroundGray);  
    color: var(--offBlack);
    padding: 5% 0;
}

.main-container>.heading {
    /* padding-top: 5%; */
    /* margin-bottom: 15px; */
    padding-bottom: 2%;
    font-weight: 600;
    font-size: 32px;
    color: var(--offBlack);
}

.banner-img {
    width: 200px;
    margin: auto;
    /* padding-bottom: 15px; */
    padding-bottom: 1%;
}

.banner-img>img {
    width: 100%;
}

.main-container>.sub-heading {
    width: 650px;
    margin: auto;
    font-weight: 400;
    font-size: 16px;
    color: var(--offBlack);
    padding-bottom: 2%;
}

.form-container>.input-container {
    display: flex;
    width: 400px;
    margin: auto;
    flex-direction: column;
    row-gap: 15px;
    justify-content: flex-start;
    text-align: left;
}

.form-container>.input-container>label {
    font-weight: 600;
    font-size: 14px;
    color: var(--offBlack);
}

.form-container>.input-container>input {
    width: 100%;
    height: 40px;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding-left: 5%;
    font-size: 16px;
    border: 1px solid #999999;
}

.form-container>.button-container {
    width: 400px;
    margin: auto;
    padding-bottom: 1.5%;
    padding-top: 1%;
}

.form-container>.button-container>button {
    width: 100%;
    height: 40px;
    border: none;
    background-color: var(--orange);
    border-radius: 25px;
    /* margin-top: 20px; */
    color: white;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}
.form-container>.button-container>button:hover{
    background-color: var(--secondaryOrange);
}
.form-container>.button-container>button:active{
    background-color: var(--tertiaryOrange);
}
.main-container>.regulation {
    /* padding-top: 70px; */
    /* padding-bottom: 70px; */
    padding: 4% 0 5% 0;
    font-weight: 400;
    font-size: 14px;
    color: var(--offBlack);
}

.dot-container {
    display: flex;
    width: 100px;
    margin: auto;
    justify-content: space-around;
    /* padding-top: 25px; */
}

.dot1,
.dot2,
.dot3 {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    cursor: pointer;
}

.dot1 {
    background-color: #58B56F;
}

.dot2,
.dot3 {
    border: 1px solid #999999;
}


.option-container {
    margin-bottom: 18px;
    display: flex;
    gap: 20px;
    font-size: 14px;
}

.option-container label {
    display: flex;
    align-items: center;
}

.option-container input[type="radio"] {
    /* transform: scale(1.1); Adjust the scale factor to your preference */
    margin-right: 5px;
}


@media (min-height: 700px) {
    .main-container{
        padding: 9% 0;
    }

    .main-container > .regulation{
        padding-top: 7%;
    }

  }
