.footer-container{
    height: 130px;
    display: flex;
    padding: 0 6% 0 7%;
    background-color: var(--primaryGreen);
    color: #F9FAF5;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
}
.footer-left{
    width: 40%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.footer-left-top > img{
    width: 170px;
    cursor: pointer;
}


.footer-right{
    width: 85%;
    display: flex;
    justify-content: flex-end;
    column-gap: 5px;
}
.footer-right > p{
    cursor: pointer;
}
@media only screen and (max-width: 760px) {
    .footer-container{
        flex-direction: column;
        padding: 5% 0 2% 2%;
        justify-content: space-around;
        align-items: flex-start;
        height: 150px;
    }
    .footer-left{
        width: 100%;
    }
    .footer-right{
        width: 100%;
        justify-content: flex-start;
        /* column-gap: 3px; */
        font-size: 12px;
    }
    .footer-left-top > img{
        width: 45%;
        height: 50%;
    }
  }

  @media (min-height: 900px) {
    .bottomFixed{
        /* position: fixed; */
        bottom: 0;
    }
  }
  
  @media only screen and (min-width: 1650px) {
    .footer-container{
        margin-top: 50px;
    }
      }